module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-149368528-1"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"diagram","short_name":"diagram","start_url":"/","icon":"src/images/D.png"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"style":{"overlay":{"position":"fixed","top":0,"left":0,"right":0,"bottom":0,"backgroundColor":"rgba(0, 0, 0, 0.75)"},"content":{"position":"absolute","border":"none","background":"#222","padding":"2rem","top":"2rem","bottom":"2rem","right":"2rem","left":"2rem","overflow":"auto","WebkitOverflowScrolling":"touch"}},"contentLabel":"Modal"}},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
