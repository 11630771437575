// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-story-js": () => import("/opt/build/repo/src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */),
  "component---src-templates-epic-js": () => import("/opt/build/repo/src/templates/epic.js" /* webpackChunkName: "component---src-templates-epic-js" */),
  "component---src-templates-stack-js": () => import("/opt/build/repo/src/templates/stack.js" /* webpackChunkName: "component---src-templates-stack-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-form-success-js": () => import("/opt/build/repo/src/pages/form-success.js" /* webpackChunkName: "component---src-pages-form-success-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-overview-js": () => import("/opt/build/repo/src/pages/overview.js" /* webpackChunkName: "component---src-pages-overview-js" */),
  "component---src-pages-sections-js": () => import("/opt/build/repo/src/pages/sections.js" /* webpackChunkName: "component---src-pages-sections-js" */),
  "component---src-pages-settings-js": () => import("/opt/build/repo/src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */)
}

